import React, { createContext, useEffect, useMemo, useState } from 'react'
import type { ProductCompositePublic } from '@centrito/api/nest/shopper/catalog/products/domain/composites'
import { useDispatch } from '@centrito/app/store'

export interface AppStateContext {
  isScrollingToTop: boolean
  setIsScrollingToTop: React.Dispatch<React.SetStateAction<boolean>>
  selectedCategoryIndex: number
  setSelectedCategoryIndex: React.Dispatch<React.SetStateAction<number>>
  isInitialized: boolean
  selectedProduct: ProductCompositePublic | undefined
  setSelectedProduct: React.Dispatch<React.SetStateAction<ProductCompositePublic | undefined>>
}

const initialContext: AppStateContext = {
  isScrollingToTop: false,
  setIsScrollingToTop: () => {},
  selectedCategoryIndex: 0,
  setSelectedCategoryIndex: () => {},
  isInitialized: false,
  selectedProduct: undefined,
  setSelectedProduct: () => {},
}

export const AppStateContext = createContext<AppStateContext>(initialContext)

const AppStateProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [isScrollingToTop, setIsScrollingToTop] = useState<boolean>(false)
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<number>(0)
  const [isInitialized, setIsInitialized] = useState<boolean>(false) // New state
  const [selectedProduct, setSelectedProduct] = useState<ProductCompositePublic | undefined>(
    undefined,
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true)
    }
  }, [dispatch, isInitialized])

  const value = useMemo(
    () => ({
      isScrollingToTop,
      setIsScrollingToTop,
      selectedCategoryIndex,
      setSelectedCategoryIndex,
      isInitialized,
      selectedProduct,
      setSelectedProduct,
    }),
    [isScrollingToTop, selectedCategoryIndex, isInitialized, selectedProduct],
  )

  return <AppStateContext.Provider value={value}>{children}</AppStateContext.Provider>
}

export default AppStateProvider
